import { Component, Input, OnInit } from '@angular/core';
import { trackByRoute } from '../../utils/track-by';
import { NavigationService } from '../../services/navigation.service';
import icRadioButtonChecked from '@iconify/icons-ic/twotone-radio-button-checked';
import icRadioButtonUnchecked from '@iconify/icons-ic/twotone-radio-button-unchecked';
import { LayoutService } from '../../services/layout.service';
import { ConfigService } from '../../services/config.service';
import { map } from 'rxjs/operators';
import { RsaService } from 'src/app/shared/service/rsa.service';

@Component({
  selector: 'vex-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {

  @Input() collapsed?: boolean;
  public view;
  collapsedOpen$ = this.layoutService.sidenavCollapsedOpen$;
  title$ = this.configService.config$.pipe(map(config => config?.sidenav.title));
  imageUrl$ = this.configService.config$.pipe(map(config => config?.sidenav.imageUrl));
  imageUrl2$ = this.configService.config$.pipe(map(config => config?.sidenav.imageUrl2));
  showCollapsePin$ = this.configService.config$.pipe(map(config => config?.sidenav.showCollapsePin));

  items = [];
  public roleCode;
  trackByRoute = trackByRoute;
  icRadioButtonChecked = icRadioButtonChecked;
  icRadioButtonUnchecked = icRadioButtonUnchecked;

  constructor(private navigationService: NavigationService,
    public layoutService: LayoutService, private rsaService: RsaService,
    private configService: ConfigService) {
    this.roleCode = JSON.parse(localStorage.getItem('3'));
    if (this.roleCode == 1) {
      this.items = this.navigationService.items;
    } else if(this.roleCode == 5) {
      this.items = this.navigationService.items5;
    }else if(this.roleCode == 6){
      this.items=this.navigationService.items6;
      console.log(this.items,'0000');

    }else {
      this.items = this.navigationService.items2;
    }
  }

  ngOnInit() {
    let access = [];
    if (localStorage.getItem('5')) {
      access = JSON.parse(this.rsaService.decrypt(localStorage.getItem('5')));
    }
    if (this.roleCode != 1 && this.roleCode !== 2 && this.roleCode !== 3 && this.roleCode !==5 && this.roleCode!==6) {
      this.items.forEach(x => {
        let y
        if (x.type === 'link') {
          y = access.find(z => z.moduleName === x.label && z.view === 1);
          (y) ? x.view = 1 : x.view = 0;
        } else {
          y = access.find(z => z.moduleName === x.label && z.view === 1);
          (y) ? x.view = 1 : x.view = 0;
          x.children.forEach(s => {
            let m = access.find(z => z.subModuleName === s.label && z.view === 1);
            (m) ? s.view = 1 : s.view = 0;
          });
        }

      })
    } else {
      this.items.forEach(x => {
        if (x.type === 'link') {
          x.view = 1;
        } else {
          x.view = 1;
          x.children.forEach(s => {
            s.view = 1;
          });
        }
      });
    }
  }

  onMouseEnter() {
    this.layoutService.collapseOpenSidenav();
    this.view = true;
  }

  onMouseLeave() {
    this.layoutService.collapseCloseSidenav();
    this.view = false;
  }

  toggleCollapse() {
    this.collapsed ? this.layoutService.expandSidenav() : this.layoutService.collapseSidenav();
  }
}
