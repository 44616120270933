import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomLayoutComponent } from './custom-layout/custom-layout.component';
import { FarmLayoutComponent } from './farm-layout/farm-layout/farm-layout.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth/login',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'farm2bag',
    component: CustomLayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
      {
        path: 'bpoDashboard',
        loadChildren: () =>
          import('./bpo-dashboard/bpo-dashboard.module').then((m) => m.BpoDashboardModule),
      },
      {
        path: 'crm',
        loadChildren: () =>
          import('./crm/crm.module').then((m) => m.CrmModule),
      },
      {
        path: 'account',
        loadChildren: () =>
          import('./account-management/account-management.module').then((m) => m.AccountManagementModule),
      },
      {
        path: 'master',
        loadChildren: () =>
          import('./master/master.module').then((m) => m.MasterModule),
      },
      {
        path: 'farmCircle',
        loadChildren: () =>
          import('./farm-circle/farm-circle.module').then(
            (m) => m.FarmCircleModule
          ),
      },
      {
        path: 'farmer',
        loadChildren: () =>
          import('./farmer/farmer.module').then(
            (m) => m.FarmerModule
          ),
      },
      {
        path: 'farm',
        loadChildren: () =>
          import('./farm/farm.module').then(
            (m) => m.FarmModule
          ),
      },
      {
        path:'accounting',
        loadChildren:()=>
        import('./accounting/accounting.module').then(
          (m) => m.AccountingModule
        )
      },
      {
        path:'logistics',
        loadChildren:()=>
        import('./logistics/logistics.module').then(
          (m) => m.LogisticsModule
        )
      },
      {
        path: 'content',
        loadChildren: () =>
          import('./content-management/content-management.module').then(
            (m) => m.ContentManagementModule
          ),
      },
      {
        path: 'product1',
        loadChildren: () =>
          import('./product/product.module').then((m) => m.ProductModule),
      },
      {
        path: 'order',
        loadChildren: () =>
          import('./order/order.module').then((m) => m.OrderModule),
      },

      {
        path: 'customer',
        loadChildren: () =>
          import('./customer/customer.module').then((m) => m.CustomerModule),
      },
      {
        path: 'farmer',
        loadChildren: () =>
          import('./farmer/farmer.module').then(
            (m) => m.FarmerModule
          ),
      },
      {
        path: 'bpo',
        loadChildren: () =>
          import('./bpo/bpo.module').then(
            (m) => m.BpoModule
          ),
      },
    ]
  },
  {
    path: 'farm-layout',
    component: FarmLayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./farm-dashboard/farm-dashboard.module').then((m) => m.FarmDashboardModule),
      },
      {
        path: 'farmer',
        loadChildren: () =>
          import('./farmer/farmer.module').then(
            (m) => m.FarmerModule
          ),
      },
      {
        path: 'product1',
        loadChildren: () =>
          import('./product/product.module').then((m) => m.ProductModule),
      },
      {
        path: 'master',
        loadChildren: () =>
          import('./master/master.module').then((m) => m.MasterModule),
      },
      {
        path:'accounting',
        loadChildren:()=>
        import('./accounting/accounting.module').then(
          (m) => m.AccountingModule
        )
      },
      {
        path: 'purchase',
        loadChildren: () =>
          import('./purchase/purchase.module').then((m) => m.PurchaseModule),
      },
      {
        path: 'order',
        loadChildren: () =>
          import('./order/order.module').then((m) => m.OrderModule),
      },
      {
        path: 'customer',
        loadChildren: () =>
          import('./customer/customer.module').then((m) => m.CustomerModule),
      },
      {
        path: 'stock',
        loadChildren: () =>
          import('./stock/stock.module').then((m) => m.StockModule),
      },
      {
        path: 'report',
        loadChildren: () =>
          import('./report/report.module').then((m) => m.ReportModule),
      },
      { path:"inventory",
        loadChildren: () =>
        import('./inventory/inventory.module').then((m)=>m.InventoryModule)}
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
