import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RsaService } from 'src/app/shared/service/rsa.service';
import { environment } from 'src/environments/environment';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  public now!: number;
  public user = {} as any;
  public baseUrl = environment.apiBaseUrl + '/login';

  constructor(private http: HttpClient, private rsaService: RsaService) {}

  loginEmail(login) {
    return this.http.post(`${this.baseUrl}/admin`, login).pipe(
      tap((x) => {
        this.setToken(x);
      })
    );
  }

  setToken(data: any): void {
    console.log(data.result);

    if (data.result?.token) {
      const token = data.result?.token;
      let loginType;
      this.now = new Date().getTime();
      localStorage.clear();
      this.user = data.result?.userDetails;
      console.log(this.user,'-------------');

      if (data.result?.loginType && data.result?.loginType === 'Admin') {
        localStorage.setItem(
          '2',
          this.rsaService.encrypt(this.user?.userName || '')
        );
        localStorage.setItem('3', JSON.stringify(this.user?.role?.roleCode));
        localStorage.setItem('6', this.rsaService.encrypt(this.user.email));
        localStorage.setItem(
          '4',
          this.rsaService.encrypt(JSON.stringify(this.user))
        );
      } else if (
        data.result?.loginType &&
        data.result?.loginType === 'FarmCircle'
      ) {
        localStorage.setItem(
          '2',
          this.rsaService.encrypt(this.user?.farmCircleName || '')
        );
        localStorage.setItem('3', JSON.stringify(2));
        localStorage.setItem(
          '4',
          this.rsaService.encrypt(JSON.stringify(this.user))
        );
        localStorage.setItem('farmCircleId', this.user._id);
      } else if (
        data.result?.loginType &&
        data.result?.loginType === 'FarmHub'
      ) {
        localStorage.setItem(
          '2',
          this.rsaService.encrypt(this.user?.farmName || '')
        );
        localStorage.setItem('3', JSON.stringify(3));
        localStorage.setItem(
          '4',
          this.rsaService.encrypt(JSON.stringify(this.user))
        );
        localStorage.setItem('id', this.user._id);
        localStorage.setItem('farmId', this.user._id);
        localStorage.setItem('farmCircleId', this.user.farmCircleId);
      } else if (
        data.result?.loginType &&
        data.result?.loginType === 'BpoUser'
      ) {
        localStorage.setItem(
          '2',
          this.rsaService.encrypt(this.user?.bpoUserName || '')
        );
        localStorage.setItem('3', JSON.stringify(5));
        localStorage.setItem(
          '4',
          this.rsaService.encrypt(JSON.stringify(this.user))
        );
        localStorage.setItem('BpoUserId', this.user._id);
        // localStorage.setItem('farmId', this.user._id);
        // localStorage.setItem('farmCircleId', this.user.farmCircleId);
      }else if (
        data.result?.loginType &&
        data.result?.loginType === 'CRM'
      ) {
        localStorage.setItem(
          '2',
          this.rsaService.encrypt(this.user.name || '')
        );
        localStorage.setItem('3', JSON.stringify(6));
        localStorage.setItem(
          '4',
          this.rsaService.encrypt(JSON.stringify(this.user))
        );
        localStorage.setItem('crmUserId', this.user._id)
      }
      localStorage.setItem('1', token);
      localStorage.setItem('7', data.result?.loginType);
      localStorage.setItem('11', this.now.toString());
      if (data.result?.access) {
        localStorage.setItem(
          '5',
          this.rsaService.encrypt(JSON.stringify(data.result?.access))
        );
      }
    }
  }

  sentforgotPasswordOtpAdmin(otp) {
    return this.http
      .post(`${this.baseUrl}/sent-forgot-password-otp-admin`, otp)
      .pipe(
        tap((x) => {
          this.setToken(x);
        })
      );
  }

  tokenVerfiy = () => {
    return this.http.get(`${this.baseUrl}/tokenVerfiy`);
  };

  login(login: { email: any; password: any }) {
    return this.http.post(`${this.baseUrl}/login`, login).pipe(
      tap((x) => {
        this.setToken(x);
      })
    );
  }

  forgotPasswordOtpAdmin(otp) {
    return this.http
      .post(`${this.baseUrl}/forgot-password-otp-admin`, otp)
      .pipe(
        tap((x) => {
          this.setToken(x);
        })
      );
  }

  logout() {
    return this.http.get(`${this.baseUrl}/logout`);
  }
}
